import styled from "styled-components";
import { Nav, NavDropdown } from "react-bootstrap";
import { navbarColor } from "theme";
import ColorPalette from "colorPalette";

const StyledNavbar = styled.div`
  background-color: ${navbarColor};
  height: 64px;
  font-weight: 500;
`;

const StyledNav = styled(Nav)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: white;
`;

const StyledLink = styled(Nav.Link)<{ isactive: string; onClick: any }>`
  padding: 10px 8px 10px 8px;
  color: ${ColorPalette.DarkGrey};
  font-size: 14px;
  border-bottom: ${(props): any =>
    props.isactive === "true"
      ? `2px solid ${ColorPalette.JaffaOrange}`
      : null}};
  padding-bottom: ${(props): any =>
    props.isactive === "true" ? "8px" : "10px"}};
  font-weight: 500;
  &:hover {
    color: ${ColorPalette.DarkGrey};
    text-decoration: none;
  }
`;

const StyledLogoText = styled.div<{ marginLeft?: string | undefined }>`
  margin-left: ${(props): string | undefined => props.marginLeft};
  color: ${ColorPalette.White};
  font-weight: 500;
`;

const StyledNavDropdown = styled(NavDropdown)`
  a.dropdown-toggle,
  a.dropdown-toggle:focus,
  a.dropdown-toggle:active {
    color: ${ColorPalette.AlabasterWhite};
    text-decoration: none;
  }

  a::after {
    color: ${ColorPalette.JaffaOrange};
  }
`;

export {
  StyledNavbar,
  StyledNav,
  StyledLink,
  StyledLogoText,
  StyledNavDropdown,
};
