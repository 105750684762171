import React from "react";
import { StyledButton } from "./Button.styles";

const Button = (props: any) => {
  return (
    <>
      <StyledButton
        backgroundcolor={props.backgroundColor}
        textcolor={props.textColor}
        bordercolor={props.borderColor}
        disabled={props.disabled}
        onClick={props.onClick}
        type={props.type}
      >
        {props.children}
      </StyledButton>
    </>
  );
};

export default Button;
