import { createGlobalStyle } from "styled-components";
import theme from "styled-theming";
import ColorPalette from "colorPalette";

export const navbarColor = theme("mode", {
  honolulu: ColorPalette.DarkGrey,
});

const fontFamily = theme("mode", {
  honolulu: "Roboto, sans-serif",
});

const backgroundColor = theme("mode", {
  honolulu: ColorPalette.AlabasterWhite,
});

//TODO: Add logo

const Wrapper = createGlobalStyle`
  body {
    font-family: ${fontFamily};
    background-color: ${backgroundColor}
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
    color: ${ColorPalette.DarkGrey};
    margin-bottom: 0px;
  }

`;

export default Wrapper;
