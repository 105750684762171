import React from "react";
import { Spinner } from "react-bootstrap";
import Box from "common/Box";

const LoadingSpinner = () => {
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Box>
  );
};

export default LoadingSpinner;
