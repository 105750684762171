const ColorPalette = {
  DarkGrey: "#485259",
  AlabasterWhite: "#F7F7F7",
  JaffaOrange: "#e67c36",
  White: "#ffffff",
  Red: "#ff0000",
  Green: "#6b8e23",
  Pelorous: "#509bc6",
  Silver: "#CCCCCC",
  RoyalBlue: "#2F80ED",
  GrayChateau: "#9da3a9",
  Boulder: "#797979",
  AlizarinCrimson: "#E12C2C",
  SteelBlue: "#488CB2",
  SanMarino: "#407C9E",
  PersianRed: "#D12929",
  TallPoppy: "#C62727",
  PelorousAlpha0: "rgba(80,155,198,.0)",
};

export default ColorPalette;
