import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './ProtectedRoute.scss';

export const ProtectedRoute = ({
  level,
  path,
  component: Component,
  render,
  ...rest
}: any) => {
  const [role, setRole] = useState('User');

  const getUserRole = async () => {
    let token = await Auth.currentSession();
    setRole(token.getIdToken().payload['custom:Role']);
  };

  useEffect(() => {
    getUserRole();
  }, []);

  if (!level.some((l: string) => l === role)) {
    return (
      <div className="page-unauthorized-container">
        <h1>Access Denied</h1>
      </div>
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          return Component ? (
            <Component path={path} {...props} {...rest} />
          ) : (
            render(props)
          );
        }}
      />
    );
  }
};
