import styled from "styled-components";
import ColorPalette from "colorPalette";
import { Button } from "react-bootstrap";
import { ButtonType } from "react-bootstrap/esm/Button";
import React, { useState } from "react";

const StyledButton = styled(Button)<{
  backgroundcolor: string;
  textcolor: string;
  bordercolor: string;
  as?: string;
  type?: ButtonType;
  href?: string;
}>`
  background-color: ${(props): string => props.backgroundcolor};
  color: ${(props): string => props.textcolor};
  border-radius: 4px;
  border-color: ${(props): string => props.bordercolor};
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: fit-content;
  height: 32px;
  font-size: 14px;
  font-weight: ${({ backgroundcolor }) =>
    (backgroundcolor === ColorPalette.PelorousAlpha0 && `regular`) || `bold`};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  &:disabled {
    background-color: ${ColorPalette.Silver};
    border-color: ${ColorPalette.Silver};
  }
  &:hover {
    background-color: ${({ backgroundcolor }) =>
      (backgroundcolor === ColorPalette.Pelorous && ColorPalette.SteelBlue) ||
      (backgroundcolor === ColorPalette.AlizarinCrimson &&
        ColorPalette.PersianRed) ||
      (backgroundcolor === ColorPalette.PelorousAlpha0 &&
        `rgba(80,155,198,.05)`) ||
      ColorPalette.Pelorous};
    border-color: ${({ backgroundcolor }) =>
      (backgroundcolor === ColorPalette.Pelorous && ColorPalette.SteelBlue) ||
      (backgroundcolor === ColorPalette.AlizarinCrimson &&
        ColorPalette.PersianRed) ||
      ColorPalette.Pelorous};
    color: ${({ backgroundcolor }) =>
      (backgroundcolor === ColorPalette.PelorousAlpha0 &&
        ColorPalette.Pelorous) ||
      ColorPalette.White};
  }
  &:not(:disabled):not(.disabled):active {
    background-color: ${({ backgroundcolor }) =>
      (backgroundcolor === ColorPalette.Pelorous && ColorPalette.SanMarino) ||
      (backgroundcolor === ColorPalette.AlizarinCrimson &&
        ColorPalette.TallPoppy) ||
      (backgroundcolor === ColorPalette.PelorousAlpha0 &&
        `rgba(80,155,198,.15)`) ||
      ColorPalette.Pelorous};
    border-color: ${({ backgroundcolor }) =>
      (backgroundcolor === ColorPalette.Pelorous && ColorPalette.SanMarino) ||
      (backgroundcolor === ColorPalette.AlizarinCrimson &&
        ColorPalette.TallPoppy) ||
      (backgroundcolor === ColorPalette.PelorousAlpha0 &&
        `rgba(80,155,198,.15)`) ||
      ColorPalette.Pelorous};
    color: ${({ backgroundcolor }) =>
      backgroundcolor === ColorPalette.PelorousAlpha0 && ColorPalette.Pelorous};
  }
  &:focus {
    background-color: ${({ backgroundcolor }) =>
      (backgroundcolor === ColorPalette.Pelorous && ColorPalette.SanMarino) ||
      (backgroundcolor === ColorPalette.AlizarinCrimson &&
        ColorPalette.TallPoppy) ||
      (backgroundcolor === ColorPalette.PelorousAlpha0 &&
        `rgba(80,155,198,.15)`) ||
      ColorPalette.Pelorous};
    border-color: ${({ backgroundcolor }) =>
      (backgroundcolor === ColorPalette.Pelorous && ColorPalette.SanMarino) ||
      (backgroundcolor === ColorPalette.AlizarinCrimson &&
        ColorPalette.TallPoppy) ||
      (backgroundcolor === ColorPalette.PelorousAlpha0 &&
        `rgba(80,155,198,.15)`) ||
      ColorPalette.Pelorous};
    color: ${({ backgroundcolor }) =>
      backgroundcolor === ColorPalette.PelorousAlpha0 && ColorPalette.Pelorous};
  }
`;

export { StyledButton };
