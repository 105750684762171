import React from 'react';
import AppRouter from './AppRouter';
import Amplify from 'aws-amplify';
import { ThemeProvider } from 'styled-components';
import Wrapper from 'theme/theme';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
});

function App() {
  return (
    <ThemeProvider theme={{ mode: 'honolulu' }}>
      <Wrapper />
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
