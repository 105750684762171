import styled from "styled-components";
import {
  alignContent,
  alignItems,
  alignSelf,
  AlignSelfProps,
  borders,
  bottom,
  color,
  ColorProps,
  display,
  DisplayProps,
  flex,
  flexBasis,
  FlexboxProps,
  flexDirection,
  FlexProps,
  flexWrap,
  fontFamily,
  fontSize,
  FontSizeProps,
  height,
  HeightProps,
  justifyContent,
  justifySelf,
  left,
  maxHeight,
  maxWidth,
  MaxWidthProps,
  minHeight,
  minWidth,
  order,
  overflow,
  position,
  right,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  top,
  verticalAlign,
  width,
  WidthProps,
  zIndex,
} from "styled-system";
import { ReactElement } from "react";

type OwnProps = {
  children?: ReactElement | Array<ReactElement> | undefined;
};

type Props = OwnProps &
  SpaceProps &
  MaxWidthProps &
  WidthProps &
  HeightProps &
  FlexProps &
  AlignSelfProps &
  TextAlignProps &
  FontSizeProps &
  DisplayProps &
  ColorProps &
  FlexboxProps;

/**
 * A div that has props for styling.
 */
const Box = styled("div")<Props>`
  "boxSizing":border-box ;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
  ${color};
  ${space};
  ${display};
  ${position};
  ${top};
  ${bottom};
  ${left};
  ${right};
  ${minWidth};
  ${maxWidth};
  ${width};
  ${fontSize};
  ${fontFamily};
  ${textAlign};
  ${borders};
  ${flex};
  ${order};
  ${alignSelf};
  ${height};
  ${minHeight};
  ${maxHeight};
  ${alignItems};
  ${alignContent};
  ${justifyContent};
  ${flexWrap};
  ${flexBasis};
  ${flexDirection};
  ${justifySelf};
  ${verticalAlign};
  ${zIndex};
  ${overflow};
`;

export default Box;
