import React, {
  lazy,
  ReactElement,
  Suspense,
  useEffect,
  useState,
} from 'react';
import NavBar from 'common/NavBar';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { ProtectedRoute } from './common/ProtectedRoute/ProtectedRoute';
import LoadingSpinner from 'common/LoadingSpinner';
import { string_to_slug } from './assets/functions';
import Auth from '@aws-amplify/auth';

const ReportingPage = lazy(() => import('containers/Reporting'));
const FundingRequests = lazy(() => import('containers/FundingRequests'));
const ScheduledJobManagement = lazy(
  () => import('containers/ScheduledJobManagement')
);
const ProjectsPage = lazy(() => import('containers/Projects'));
const SMSPage = lazy(() => import('containers/SMSPage'));
const NewProjectPage = lazy(() => import('containers/NewProject'));
const Adjustments = lazy(() => import('containers/Adjustments'));
const EditProjectPage = lazy(() => import('containers/EditProject'));
const NewOrdersPage = lazy(() => import('containers/NewOrder'));
const SingleOrderFormPage = lazy(() => import('containers/SingleOrderForm'));
const OrderConfirmationPage = lazy(
  () => import('containers/OrderConfirmation')
);
const ViewProjectPage = lazy(() => import('containers/ViewProject'));
const BulkResult = lazy(() => import('containers/BulkResult'));
const PaymentResult = lazy(() => import('containers/PaymentResult'));
const BulkCSVPage = lazy(() => import('containers/BulkCSV'));
const MassFunding = lazy(() => import('containers/Funding/MassFunding'));
const BulkOrderPage = lazy(() => import('containers/BulkCardOrder'));
const AccountsPage = lazy(() => import('containers/Accounts'));
const ViewAccountPage = lazy(() => import('containers/ViewAccount'));
const Funding = lazy(() => import('containers/Funding'));
const OrderDetailsPage = lazy(() => import('containers/OrderDetails'));
const CardDetailsPage = lazy(() => import('containers/CardDetails'));
const LoadFundsPage = lazy(() => import('containers/LoadFunds'));
const LoadFundsErrorPage = lazy(() => import('containers/LoadFundsError'));
const UserDeactivationPage = lazy(() => import('containers/UserDeactivation'));

const AppRouter = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <Route exact path={'/'} render={(): ReactElement => <BaseRoute />} />
          <Route
            exact
            path={'/:municipality'}
            render={(): ReactElement => <BaseRoute />}
          />
          {/* <Redirect exact from="/" to={`/municipality/reporting`} /> */}
          {/* <Redirect
            exact
            from="/:municipality"
            to={`/municipality/reporting`}
          /> */}
          <URLRoute
            path="/:municipality/fund/:id"
            render={(): ReactElement => <Funding />}
          />
          <URLRoute
            privateRoute={true}
            path="/:municipality/reporting"
            level={['Admin']} // Tipes of users allowed in the route
            component={ReportingPage}
          />
          <URLRoute
            path={'/:municipality/projects'}
            render={(): ReactElement => <ProjectsPage />}
          />
          <URLRoute
            privateRoute={true}
            path="/:municipality/funding-requests"
            level={['Admin']} // Tipes of users allowed in the route
            component={FundingRequests}
          />
          <Route
            path="/:municipality/scheduled-jobs-management"
            level={['Admin']} // Tipes of users allowed in the route
            component={ScheduledJobManagement}
          />
          <URLRoute
            privateRoute={true}
            path="/:municipality/adjustments/card/:id"
            level={['Admin']} // Tipes of users allowed in the route
            component={Adjustments}
          />
          <URLRoute
            privateRoute={true}
            path="/:municipality/adjustments/order/:id"
            level={['Admin']} // Tipes of users allowed in the route
            component={Adjustments}
          />
          <URLRoute
            path="/:municipality/project/:id/sms"
            render={(): ReactElement => <SMSPage />}
          />
          <URLRoute
            path="/:municipality/new-project"
            render={(): ReactElement => <NewProjectPage />}
          />
          <URLRoute
            path="/:municipality/edit-project/:projectId"
            render={(): ReactElement => <EditProjectPage />}
          />
          <URLRoute
            path="/:municipality/new-order"
            render={(): ReactElement => <NewOrdersPage />}
          />
          <URLRoute
            path="/:municipality/single-order-form"
            render={(): ReactElement => <SingleOrderFormPage />}
          />
          <URLRoute
            path="/:municipality/view-project/:projectId"
            render={(): ReactElement => <ViewProjectPage />}
          />
          <URLRoute
            path="/:municipality/order-confirmation"
            render={(): ReactElement => <OrderConfirmationPage />}
          />
          <URLRoute
            path="/:municipality/bulk-CSV"
            render={(): ReactElement => <BulkCSVPage />}
          />
          <Route
            exact
            path="/:municipality/bulk-order"
            render={(): ReactElement => <BulkOrderPage />}
          />
          <URLRoute
            path="/:municipality/user-bulk-result/:id"
            render={(): ReactElement => <BulkResult />}
          />
          <URLRoute
            path="/:municipality/payment-bulk-result/:id"
            render={(): ReactElement => <PaymentResult />}
          />
          <URLRoute
            path="/:municipality/accounts"
            render={(): ReactElement => <AccountsPage />}
          />
          <URLRoute
            path="/:municipality/view-account"
            render={(): ReactElement => <ViewAccountPage />}
          />
          <URLRoute
            path="/:municipality/order-details/:orderId/mass-pick-up"
            render={(): ReactElement => <MassFunding />}
          />
          <URLRoute
            path="/:municipality/order-details/:orderId"
            render={(): ReactElement => <OrderDetailsPage />}
          />
          <URLRoute
            path="/:municipality/card-details"
            render={(): ReactElement => <CardDetailsPage />}
          />
          <URLRoute
            path="/:municipality/load-funds"
            render={(): ReactElement => <LoadFundsPage />}
          />
          <URLRoute
            path="/:municipality/load-funds-error"
            render={(): ReactElement => <LoadFundsErrorPage />}
          />
          <URLRoute
            path="/:municipality/user-deactivation"
            render={(): ReactElement => <UserDeactivationPage />}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;

const URLRoute = (props: any) => {
  const [municipality, setMunicipality] = useState('');
  const history = useHistory();

  const { path, render, privateRoute, level, component } = props;

  const getUserRole = async () => {
    let token = await Auth.currentSession();
    setMunicipality(token.getIdToken().payload['custom:Municipality']);
  };

  let pathArray = window.location.pathname.split('/');
  let secondLevelLocation = pathArray[1];

  useEffect(() => {
    getUserRole();
  }, []);

  if (
    string_to_slug(municipality) &&
    string_to_slug(municipality) !== secondLevelLocation
  ) {
    history.push(`/${string_to_slug(municipality)}/projects`);
  }

  if (privateRoute) {
    return (
      <ProtectedRoute
        path={path}
        level={level} // Tipes of users allowed in the route
        component={component}
      />
    );
  } else {
    return <Route exact path={path} render={render} />;
  }
};

const BaseRoute = (props: any) => {
  const { path, render, privateRoute, level, component } = props;
  const [municipality, setMunicipality] = useState('');
  const history = useHistory();

  const getUserRole = async () => {
    let token = await Auth.currentSession();
    setMunicipality(token.getIdToken().payload['custom:Municipality']);
  };

  let pathArray = window.location.pathname.split('/');
  let secondLevelLocation = pathArray[1];
  let thirdLevelLocation = pathArray[2];

  useEffect(() => {
    getUserRole();
  }, []);

  if (
    string_to_slug(municipality) &&
    string_to_slug(municipality) !== secondLevelLocation
    //  ||!thirdLevelLocation
  ) {
    history.push(`/${string_to_slug(municipality)}/projects`);
  }

  if (
    string_to_slug(municipality) &&
    !thirdLevelLocation
    //  ||!thirdLevelLocation
  ) {
    history.push(`/${string_to_slug(municipality)}/projects`);
  }

  return <div className="base-route-component"></div>;
};
