import React, { useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  StyledNavbar,
  StyledLink,
  StyledNav,
  StyledLogoText,
  StyledNavDropdown,
} from './NavBar.styles';
import Box from 'common/Box';
import { Context } from 'AuthStateProvider';
import { NavDropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import miami from '../../images/cities/miami/logo.png';
import atlanta from '../../images/cities/atlanta/logo.png';
import mocafiLogo from '../../images/mocafi-logo.jpg';
import { string_to_slug } from '../../assets/functions';
import './NavBar.scss';
// Function that validates URL municipality and conditionally returns the respective logo
const validateCityLogo = (municipality: String) => {
  const url = window.location.href;
  const host = window.location.host;
  const subdomain = host.split('.')[0];

  if (municipality === 'miami') {
    return miami;
  }
  if (municipality === 'atlanta') {
    return atlanta;
  } else {
    return mocafiLogo;
  }
};

async function signOut(history: any, municipality: string) {
  try {
    await Auth.signOut();
    history.push(`/${municipality}`);
    window.location.reload();
    localStorage.clear();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

const NavBar = () => {
  const history = useHistory();
  const [role, setRole] = useState('User');
  const [municipality, setMunicipality] = useState('');

  const context = useContext(Context);

  const getUserRole = async () => {
    let token = await Auth.currentSession();
    setRole(token.getIdToken().payload['custom:Role']);
    setMunicipality(
      string_to_slug(token.getIdToken().payload['custom:Municipality'])
    );
  };

  useEffect(() => {
    getUserRole();
  }, []);

  return (
    <>
      <StyledNavbar>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          height="100%"
          justifyContent="space-between"
          pl="100px"
          pr="100px"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
          >
            <img
              alt="mocafi logo"
              src={validateCityLogo(
                context.userInfo.municipality.toLowerCase()
              )}
              style={{ height: '42px', marginRight: '12px' }}
            />
            <StyledLogoText>
              {/* MoCaFi IRC Portal */}
              {context.userInfo.municipality[0].toUpperCase() +
                context.userInfo.municipality.slice(1)}
            </StyledLogoText>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <StyledNavDropdown
              title={
                context.userInfo.firstName + ' ' + context.userInfo.lastName
              }
              id="basic-nav-dropdown"
            >
              {context.userInfo.isAdmin ? (
                <>
                  <NavDropdown.Item
                    href={`/${string_to_slug(
                      context.currentProject?.municipalProgram?.municipality
                    )}/accounts`}
                  >
                    Accounts
                  </NavDropdown.Item>
                </>
              ) : (
                <></>
              )}
              <NavDropdown.Divider />
              <NavDropdown.Item>
                <button
                  className="logout-button"
                  onClick={() => signOut(history, municipality)}
                >
                  SIGN OUT
                </button>
              </NavDropdown.Item>
            </StyledNavDropdown>
          </Box>
        </Box>
      </StyledNavbar>
      <StyledNav>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          height="100%"
          justifyContent="space-between"
          pl="100px"
          pr="100px"
        >
          <StyledLink
            isactive={(
              context.currentNavigationTab === '/:municipality/projects'
            ).toString()}
            onClick={() => {
              localStorage.setItem(
                'currentNavigationTab',
                JSON.stringify('/:municipality/projects')
              );
              context.setNavigationTab('/:municipality/projects');
            }}
            as={Link}
            to={`/${municipality}/projects`}
          >
            Projects
          </StyledLink>

          {role === 'Admin' ? (
            <StyledLink
              isactive={(
                context.currentNavigationTab === '/:municipality/reporting'
              ).toString()}
              onClick={() => {
                localStorage.setItem(
                  'currentNavigationTab',
                  JSON.stringify('/reporting')
                );
                context.setNavigationTab(`/:municipality/reporting`);
              }}
              as={Link}
              to={`/${municipality}/reporting`}
            >
              Reporting
            </StyledLink>
          ) : (
            <div></div>
          )}

          {role === 'Admin' ? (
            <StyledLink
              isactive={(
                context.currentNavigationTab ===
                '/:municipality/funding-requests'
              ).toString()}
              onClick={() => {
                localStorage.setItem(
                  'currentNavigationTab',
                  JSON.stringify('/funding-requests')
                );
                context.setNavigationTab('/:municipality/funding-requests');
              }}
              as={Link}
              to={`/${municipality}/funding-requests`}
            >
              Funding Requests
            </StyledLink>
          ) : (
            <div></div>
          )}
          <StyledLink
            isactive={(
              context.currentNavigationTab ===
              '/:municipality/scheduled-jobs-management'
            ).toString()}
            onClick={() => {
              localStorage.setItem(
                'currentNavigationTab',
                JSON.stringify('/scheduled-jobs-management')
              );
              context.setNavigationTab(
                '/:municipality/scheduled-jobs-management'
              );
            }}
            as={Link}
            to={`/${municipality}/scheduled-jobs-management`}
          >
            Scheduled Jobs
          </StyledLink>
        </Box>
      </StyledNav>
    </>
  );
};

export default withRouter(NavBar);
