import React, { createContext, useState } from 'react';
import './App.css';
import {
  AmplifyAuthenticator,
  AmplifyForgotPassword,
  AmplifySignIn,
} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import AuthUser from 'types/AuthUser';
import ProjectAttributes from 'types/ProjectAttributes';
import BulkOrderCSVAttributes from 'types/BulkOrderCSVAttributes';
import AccountAttributes from 'types/AccountAttributes';
import { OrderInfo } from 'types/OrderInfo';
import { Auth } from 'aws-amplify';
import CardInfo from 'types/CardInfo';
import { getDomain } from './services/municipalityService';
import { getCognitoToken } from './helpers/cognitoToken';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import ColorPalette from 'colorPalette';
import Button from 'common/Button';
import { string_to_slug } from './assets/functions';

interface GlobalContext {
  userInfo: AuthUser;
  setCurrentProject: React.Dispatch<
    React.SetStateAction<ProjectAttributes | undefined>
  >;
  currentProject: ProjectAttributes | undefined;
  currentSavedBulkCSV: BulkOrderCSVAttributes[] | undefined;
  setCurrentSavedBulkCSV: React.Dispatch<
    React.SetStateAction<BulkOrderCSVAttributes[] | undefined>
  >;
  setCurrentAccount: React.Dispatch<
    React.SetStateAction<AccountAttributes | undefined>
  >;
  currentAccount: AccountAttributes | undefined;
  currentNavigationTab: string;
  setNavigationTab: React.Dispatch<React.SetStateAction<string>>;
  singleOrderResident: boolean;
  setSingleOrderReciever: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentOrder: React.Dispatch<React.SetStateAction<OrderInfo | undefined>>;
  currentOrder: OrderInfo | undefined;
  currentCard: CardInfo | undefined;
  setCurrentCard: React.Dispatch<React.SetStateAction<CardInfo | undefined>>;
  setAuthUser: React.Dispatch<React.SetStateAction<any>>;
}

export const Context = createContext<GlobalContext>({
  userInfo: {
    isSignedIn: false,
    username: '',
    isAdmin: false,
    groupId: -1,
    isApprover: false,
    maxAmountAuthCard: 0,
    maxAmountAuthLifetime: 0,
    maxAmountAuthOrder: 0,
    municipality: '',
    firstName: '',
    lastName: '',
  },
  setCurrentProject: () => {},
  currentProject: undefined,
  setCurrentAccount: () => {},
  currentAccount: undefined,
  currentNavigationTab: '/projects',
  setNavigationTab: () => {},
  currentSavedBulkCSV: undefined,
  setCurrentSavedBulkCSV: () => {},
  singleOrderResident: true,
  setSingleOrderReciever: () => {},
  setCurrentOrder: () => {},
  currentOrder: undefined,
  setCurrentCard: () => {},
  currentCard: undefined,
  setAuthUser: () => {},
});

const AuthStateApp = ({ children }: any) => {
  // Retrieving Values from local storage to populate on refresh
  const storedProject: ProjectAttributes | undefined = JSON.parse(
    localStorage.getItem('currentProject') || '{}'
  );
  const storedBulkCSVUpload: BulkOrderCSVAttributes[] | undefined = JSON.parse(
    localStorage.getItem('currentSavedBulkCSV') || '{}'
  );

  const storedAccount: AccountAttributes | undefined = JSON.parse(
    localStorage.getItem('currentAccount') || '{}'
  );

  const storedCard: CardInfo | undefined = JSON.parse(
    localStorage.getItem('currentCard') || '{}'
  );

  const storedCurrentNavigationTab: string = Object.keys(
    JSON.parse(localStorage.getItem('currentNavigationTab') || '{}')
  ).length
    ? JSON.parse(localStorage.getItem('currentNavigationTab') || '{}')
    : '/projects';

  const storedOrderResident: boolean =
    JSON.parse(localStorage.getItem('currentSavedBulkCSV') || 'true') === 'true'
      ? true
      : false;

  // Setting Global Context State Values
  const storedOrder: OrderInfo | undefined = JSON.parse(
    localStorage.getItem('currentOrder') || '{}'
  );

  const [authUser, setAuthUser] = useState<AuthUser>();
  const [municipality, setMunicipality] = useState<String>('');
  const [currentProject, setCurrentProject] = useState<
    ProjectAttributes | undefined
  >(storedProject);
  const [currentAccount, setCurrentAccount] = useState<
    AccountAttributes | undefined
  >(storedAccount);
  const [currentNavigationTab, setNavigationTab] = useState<string>(
    storedCurrentNavigationTab
  );
  const [currentSavedBulkCSV, setCurrentSavedBulkCSV] = useState<
    BulkOrderCSVAttributes[] | undefined
  >(storedBulkCSVUpload);
  const [singleOrderResident, setSingleOrderReciever] =
    useState<boolean>(storedOrderResident);

  const [currentCard, setCurrentCard] = useState<CardInfo | undefined>(
    storedCard
  );

  const [currentOrder, setCurrentOrder] = useState<OrderInfo | undefined>(
    storedOrder
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const history = useHistory();

  const ModalStyle = styled(Modal.Body)`
    border-radius: 6px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    padding: 24px;
    width: 500px;
    margin: auto;
    height: auto;
  `;

  async function signOut() {
    try {
      await Auth.signOut();
      localStorage.clear();
      history.push('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const validateURL = async (user: any) => {
    // setAuthUser(user);

    let pathArray = window.location.pathname.split('/');
    let secondLevelLocation = pathArray[1];

    let url = window.location.hostname;
    if (
      url === 'localhost' ||
      window.location.hostname.includes('develop') ||
      user.isAdmin
    ) {
      setAuthUser(user);
    } else {
      getDomain(user.municipality, await getCognitoToken())
        .then((response) => {
          // Validate URL
          if (
            (response.success &&
              string_to_slug(response.data.municipality) ===
                secondLevelLocation) ||
            !secondLevelLocation ||
            user.isSignedIn
          ) {
            setAuthUser(user);
          } else {
            setOpenModal(true);
            signOut();
          }
        })
        .catch((error) => {
          console.log('error:', error);
        });
    }
  };

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState: AuthState, authData: any) => {
      const authUser: AuthUser = {
        isSignedIn: AuthState.SignedIn === nextAuthState,
        isAdmin: getAttribute(authData, 'custom:Role') === 'Admin',
        username: authData?.username,
        groupId: +getAttribute(authData, 'custom:GroupID') || 0,
        isApprover:
          getAttribute(authData, 'custom:Approver')?.toLowerCase() === 'true',
        maxAmountAuthCard:
          +getAttribute(authData, 'custom:MaxAmtAuthCard') || 0,
        maxAmountAuthLifetime:
          +getAttribute(authData, 'custom:MaxAmtAuthLifetime') || 0,
        maxAmountAuthOrder:
          +getAttribute(authData, 'custom:MaxAmtAuthOrder') || 0,
        municipality: getAttribute(authData, 'custom:Municipality'),
        firstName: getAttribute(authData, 'given_name'),
        lastName: getAttribute(authData, 'family_name'),
      };
      validateURL(authUser);
      setMunicipality(municipality.toLowerCase());
      Auth.configure();
    });
  }, []);

  // Function that validates URL municipality and conditionally returns the respective logo
  const validateCityLogo = () => {
    const url = window.location.href;

    let municipality = url.split('/')[3];

    if (municipality === 'miami') {
      return `./cities/${municipality}/logo.png`;
    } else {
      return `./cards/${municipality}.png`;
    }
  };

  return authUser?.isSignedIn ? (
    <div className="App">
      <Context.Provider
        value={{
          userInfo: authUser,
          currentProject,
          setCurrentProject,
          currentAccount,
          setCurrentAccount,
          setNavigationTab,
          currentNavigationTab,
          setCurrentSavedBulkCSV,
          currentSavedBulkCSV,
          singleOrderResident,
          setSingleOrderReciever,
          setCurrentOrder,
          currentOrder,
          setCurrentCard,
          currentCard,
          setAuthUser,
        }}
      >
        {children}
      </Context.Provider>
    </div>
  ) : (
    <div className="login-image-container">
      <img
        src={validateCityLogo()}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = './cards/mocafi-logo.jpg';
        }}
      />
      <Modal show={openModal} centered>
        <ModalStyle>
          <div className="auth-state-provider-modal-container">
            <img
              src={validateCityLogo()}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = './cards/mocafi-logo.jpg';
              }}
            />{' '}
            <h2>Unauthorized Access</h2>
            <Button
              style={{ padding: '0px 12px' }}
              backgroundColor={ColorPalette.Pelorous}
              textColor={ColorPalette.White}
              borderColor={ColorPalette.Pelorous}
              onClick={() => setOpenModal(false)}
            >
              Accept
            </Button>
          </div>
        </ModalStyle>
      </Modal>
      <AmplifyAuthenticator>
        <AmplifySignIn
          hideSignUp={true}
          slot="sign-in"
          headerText="MoCaFi Immediate Response Card Portal"
          usernameAlias="email"
        />
        <AmplifyForgotPassword usernameAlias="email" />
      </AmplifyAuthenticator>
    </div>
  );
};

const getAttribute = (data: any, attr: string): string => {
  if (data && data.attributes && data.attributes.hasOwnProperty(attr)) {
    return data.attributes[attr];
  }
  return '';
};

export default AuthStateApp;
